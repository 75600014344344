import type { ComponentType } from 'react'
import { useEffect, useState } from 'react'
import type { Variation } from 'src/components/product-page/Selector/types'
import SlideOver from 'src/components/ui/SlideOver/SlideOver'
import { useGetRating } from 'src/components/product/CustomerReviews/hooks/useGetRating'
import ProductTitle from 'src/components/product/ProductTitle/ProductTitle'

import { DefaultSelector } from './DefaultSelector'
import { PictureSelector } from './PictureSelector'
import type { ProductItemKit, Productkit } from '../hook/KitLookContext'
import { ProductPricing } from '../../ProductPricing'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const COMPONENTS: Record<string, ComponentType<any>> = {
  Cor: PictureSelector,
  Sabor: DefaultSelector,
  Tamanho: DefaultSelector,
  Valor: DefaultSelector,
  Voltagem: DefaultSelector,
}

interface Props {
  variations: Variation[]
  onClickVariation: (name: any, value: string) => void
  product: Productkit
}

type Rating = {
  total_ratings_average_note: number
  total_reviews: number
}

export function RenderedSelectors({
  variations,
  onClickVariation,
  product,
}: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [getRatingData] = useGetRating()

  const [rating, setRating] = useState({} as Rating)

  const currentSku = (product.items.find((item) => item.selected) ??
    product.items.find((item) => item.isAvailable)) as ProductItemKit

  const currentSeller =
    currentSku?.sellers?.find((seller) => seller.sellerDefault) ??
    currentSku?.sellers?.[0]

  const offer = {
    hasDiscount:
      currentSeller?.commertialOffer.Price <
      currentSeller?.commertialOffer.ListPrice,
    price: currentSeller?.commertialOffer.Price,
    listPrice: currentSeller?.commertialOffer.ListPrice,
  }

  useEffect(() => {
    async function getInfRating() {
      try {
        const { getRating } = await getRatingData({
          productCode: Number(product.productId),
          direction: 'desc',
          page: 1,
          nb: 1,
          keep_locales_order: 1,
          locales: 'pt_BR,pt_PT,es_ES,en,fr_FR,it_IT',
          site: 1078,
          sort: 'createdAt',
        })

        setRating({
          total_ratings_average_note:
            getRating?.total_ratings_average_note ?? 0,
          total_reviews: getRating?.total_reviews ?? 0,
        })
      } catch (e) {
        console.warn(e)
      }
    }

    getInfRating()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function closeSidebarModal() {
    setSidebarOpen(false)
    document.getElementsByTagName('html')?.[0]?.classList.remove('no-scroll')
  }

  return (
    <>
      <button
        className="flex items-center justify-between w-full"
        onClick={() => setSidebarOpen(true)}
      >
        <ul className="flex items-center">
          {variations?.map((variation, idx) => {
            const valueSelect =
              variation.values.find((item) => item.selected) ??
              variation.values[0]

            return (
              <li
                key={variation.field.id}
                className={`flex item-center gap-1 px-2 h-fit ${
                  idx > 0 ? 'border-l border-gray' : 'pl-0'
                }`}
              >
                <p className="font-roboto text-sm font-bold text-deepGray ">
                  {variation.field.name}:
                </p>
                <p className="font-roboto text-sm text-deepGray">
                  {valueSelect.name}
                </p>
              </li>
            )
          })}
        </ul>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M13.1722 12L8.22217 7.05L9.63617 5.636L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z"
            fill="#00689D"
          />
        </svg>
      </button>

      <SlideOver
        isOpen={sidebarOpen}
        onDismiss={() => closeSidebarModal()}
        size="partial"
        direction="rightSide"
        className="!max-w-[491px] !w-full px-6 py-8 flex flex-col"
      >
        <button
          title="fechar Modal"
          aria-label="close-button-modal"
          className="absolute top-2 right-2"
          onClick={() => closeSidebarModal()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M16 14.1146L22.6 7.51465L24.4853 9.39998L17.8853 16L24.4853 22.6L22.6 24.4853L16 17.8853L9.39998 24.4853L7.51465 22.6L14.1146 16L7.51465 9.39998L9.39998 7.51465L16 14.1146Z"
              fill="#00689D"
            />
          </svg>
        </button>

        <ProductTitle
          brandName={product?.brand}
          title={product?.productName}
          refNumber={currentSku?.manufacturerCode ?? product?.productId}
          productBenefits={product?.productBenefits}
          itemId={currentSku?.itemId}
          seller={currentSku?.sellers[0]?.sellerId}
          offer={currentSku?.offer}
          flags={currentSku?.flags}
          rating={rating}
        />

        <ProductPricing
          item={{ ...currentSku, offer }}
          productId={product.productId}
        />

        <div className="pt-6  border-t border-gray flex flex-col gap-6">
          {variations.map((variation) => {
            const variationName = variation.field.name
            const Component = COMPONENTS[variationName]

            const props = {
              variation,
              onClickVariation,
            }

            if (!Component) {
              return <DefaultSelector key={variation.field.id} {...props} />
            }

            return (
              <Component
                key={variation.field.id}
                {...props}
                items={product.items}
              />
            )
          })}
        </div>

        <button
          onClick={() => closeSidebarModal()}
          className="w-full h-12 mt-auto mb-0 flex items-center justify-center rounded bg-backgroundPrimary py-[14px] text-white font-roboto font-bold"
        >
          Aplicar
        </button>
      </SlideOver>
    </>
  )
}
