import type { ReactNode } from 'react'
import { useBuyButton } from 'src/components/restructure/product/sections/Sidebar/ProductAction/BuyButton/hooks/useBuyButton'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import type {
  Product,
  ProductItem,
} from 'src/components/product-page/context/types/product.type'

type Notification = {
  location: 'productCard' | 'frete'
  productId?: string
  message?: string
}
export interface Productkit extends Product {
  sport?: string
  qnt?: number
  items: ProductItemKit[]
  selected?: boolean
  linkText?: string
  productReference?: string
}

export interface ProductItemKit extends ProductItem {
  isValidToBuy?: boolean
  selected?: boolean
  ean?: string
}

type KitLookData = {
  products: Productkit[]
  resume: {
    qnt?: number
    totalPrice?: number
  }
  mainProduct: Product
  changeQntProduct(type: 'more' | 'less', productId: string): void
  toggleProductActive: (productId: string, skuId: string) => void
  selectProductSku: (productId: string, skuId: string) => void
  addToCard(productsAddCart: Productkit[]): void
  notification: Notification[] | null
  setNotification: (value: Notification[] | null) => void
  changeValidBuySku: (skuId: string) => void
}

type KitLookProps = {
  children: ReactNode
  products: Productkit[]
  mainProduct: Product
}

type ItemCard = {
  cep?: string
  cepIsValidForBuy?: boolean
  id: string
  listPrice: number
  price: number
  refId?: string
  seller: { identifier: string }
  sellerName?: string
  quantity: number
  productRefId?: string
  itemOffered: {
    brand: {
      name: string
    }
    isVariantOf: {
      productGroupID: string
      name: string
    }
    gtin: string
    image: Array<{ alternateName: string; url: string }>
    name: string
    sku: string
    slug: string
    variant: Array<{
      name: string
      values: string[]
    }>
  }
}

const KitLookContext = createContext({} as KitLookData)

export function KitLookContextProvider({
  children,
  products,
  mainProduct,
}: KitLookProps) {
  const [productsSelect, setProductsSelect] = useState<Productkit[]>(products)
  const [notification, setNotification] = useState<Notification[] | null>(null)
  const { addToCart } = useBuyButton()

  function selectProductSku(productId: string, skuId: string) {
    const newList = [...productsSelect]

    const currentProduct = newList.find(
      (product) => product.productId === productId
    )

    if (!currentProduct) {
      return
    }

    const newItems = currentProduct?.items.map((item) => ({
      ...item,
      selected: item.itemId === skuId,
    }))

    currentProduct.items = newItems

    setProductsSelect(newList)
  }

  function toggleProductActive(productId: string, skuId: string) {
    const newList = [...productsSelect]

    const currentProduct = newList.find(
      (product) => product.productId === productId
    )

    if (!currentProduct) {
      return
    }

    const currentSku = currentProduct.items.find(
      (item) => item.itemId === skuId
    )

    if (currentSku) {
      currentSku.selected = true
    }

    currentProduct.selected = !currentProduct?.selected

    setProductsSelect(newList)
  }

  function changeQntProduct(type: 'more' | 'less', productId: string) {
    const newList = [...productsSelect]

    const currentProduct = newList.find((item) => item.productId === productId)

    if (!currentProduct) {
      return
    }

    if (!currentProduct.qnt) {
      currentProduct.qnt = 1
    }

    if (type === 'more') {
      currentProduct.qnt += 1
    } else if (type === 'less' && currentProduct?.qnt > 1) {
      currentProduct.qnt -= 1
    }

    setProductsSelect(newList)
  }

  function changeValidBuySku(skuId: string) {
    products.forEach((product) => {
      product.items.forEach((item) => {
        if (item.itemId === skuId) {
          setNotification([
            {
              location: 'productCard',
              productId: product.productId,
              message: 'Produto indisponível para o seu CEP.',
            },
            {
              location: 'frete',
              message:
                'Produto indisponível para o seu CEP. Escolha outro tamanho ou cor. Se preferir, altere o CEP de entrega.',
            },
          ])
        }
      })
    })
  }

  const resume = useMemo(() => {
    return productsSelect.reduce(
      (acc, item) => {
        if (item.selected) {
          const itemActive =
            item.items.find((sku) => sku.selected) ?? item.items[0]

          const currentSeller = itemActive?.sellers.find(
            (seller) => seller.sellerDefault
          )

          acc.itemsSelect.push(itemActive)

          acc.qnt += item.qnt ?? 1
          acc.totalPrice +=
            (currentSeller?.commertialOffer.Price ?? 0) * (item.qnt ?? 1)
        }

        return acc
      },
      { qnt: 0, totalPrice: 0, itemsSelect: [] as ProductItemKit[] }
    )
  }, [productsSelect])

  const addToCard = useCallback(
    (productsCart: Productkit[]) => {
      const itemsSelect = productsCart
        .filter((item) => item.selected)
        .map((item) => {
          const itemActive = item.items.find((sku) => sku.selected)

          return {
            ...item,
            itemActive,
          }
        })

      if (itemsSelect?.length < 1) {
        return
      }

      const allProductsIsValid = itemsSelect.every((item) => {
        const currentSeller =
          item.itemActive!.sellers.find((seller) => seller.sellerDefault) ??
          item.itemActive!.sellers[0]

        const isQntISValid =
          (item.qnt ?? 1) <= currentSeller.commertialOffer.AvailableQuantity

        const itemIsAvaialbe = currentSeller.commertialOffer.IsAvailable

        return isQntISValid && itemIsAvaialbe
      })

      if (!allProductsIsValid) {
        return
      }

      const formatItemsCard = itemsSelect.reduce((acc, item) => {
        const currentSeller =
          item.itemActive!.sellers.find((seller) => seller.sellerDefault) ??
          item.itemActive!.sellers[0]

        const newItem: ItemCard = {
          id: item.itemActive!.itemId,
          quantity: item.qnt ?? 1,
          price: currentSeller.commertialOffer.Price,
          listPrice: currentSeller.commertialOffer.ListPrice,
          seller: {
            identifier: currentSeller.sellerId,
          },
          itemOffered: {
            brand: {
              name: item.brand ?? ' ',
            },
            gtin: item.itemActive!.ean ?? '', //! validar oque é esse campo gtin
            image: item.itemActive!.images.map((image) => ({
              alternateName: image.imageText,
              url: image.imageUrl,
            })),
            isVariantOf: {
              productGroupID: item.productId,
              name: item.productName,
            },
            variant: item.itemActive!.variations.map((variant) => ({
              name: variant,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              values: item.itemActive[variant] as string[],
            })),
            name: item.productName,
            sku: item.itemActive!.itemId,
            slug: item.linkText ?? '',
          },
        }

        acc.push(newItem)

        return acc
      }, [] as ItemCard[])

      addToCart(
        formatItemsCard.map((item, index) => {
          return {
            id: item.id,
            productId: item?.itemOffered?.isVariantOf?.productGroupID,
            seller: item.seller?.identifier ?? '1',
            quantity: item.quantity,
            index,
          }
        })
      )
    },
    [addToCart]
  )

  return (
    <KitLookContext.Provider
      value={{
        products: productsSelect,
        resume,
        changeQntProduct,
        mainProduct,
        toggleProductActive,
        selectProductSku,
        addToCard,
        notification,
        setNotification,
        changeValidBuySku,
      }}
    >
      {children}
    </KitLookContext.Provider>
  )
}

export const useKitLook = () => useContext(KitLookContext)
